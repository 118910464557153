.dashboard_navbar {
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 3px 22px 0px #D6DAFE;
  height: 68px;

}

.update_profile_modal {
  z-index: 1000;
}

.modal-backdrop {
  background-color: transparent;
  pointer-events: none;
}

.dashbaord_navbar_crm_text {
  font-family: Roboto;
  font-size: 36px;
  font-weight: 800;
  line-height: 47px;
  text-align: center;
}

.dashbaord_navbar_crm_text span {
  color: rgba(255, 112, 6, 1);
}

.dashboard_navbar_dashboard_text {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
}



.search_main {
  display: inline-flex;
  border-radius: 10px;
  margin: 0 20px;
  padding: 3px 40px;
  width: 100%;
}

.search_main .input {
  width: 100%;
  padding: 5px 13px;
  font-size: 15px;
  font-family: sans-serif;
  border: 1px solid #d1d1d1;
  border-right: none;
  outline: none;
  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: transparent;
}

.input::placeholder {
  color: #bebbbb;
}

.search_main .icon svg#search {
  color: #554d4d;
}


.search_main .icon {
  width: 90px;
  font-size: 20px;
  border: 1px solid #d1d1d1;
  border-left: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: pointer;
  background: transparent;
}


.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  color: #333333;
  background: #dddddd;
  border: none;
  outline: none;
  border-radius: 50%;
}


.sidebar_navItems a:active svg {
  background-color: blue;
}

.material-icons {
  font-size: 15px !important;
}

.icon-button:hover {
  cursor: pointer;
  background: #cccccc;
}

.icon-button:active {
  background: #6a6a6a;
}

.icon-button__badge {
  font-size: 11px;
  position: absolute;
  top: -5px;
  right: -4px;
  width: 15px;
  height: 15px;
  background: red;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 9px;

}


.notification_section {
  padding-right: 15px;
}

.notification_section button.icon-button svg {
  font-size: 22px;
}

.dashboard_section2_table_mainDiv {
  min-height: 300px;
}

.dashboard_navbar_notification_icon {
  width: 54px;
  height: 54px;
  gap: 0px;
  border: 1px;

  color: rgba(145, 155, 250, 1);
}

.dashboard_navbar_userImg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 2px solid rgb(187 187 189);
  overflow: hidden;
}

.navbar_right_menu_img {
  padding: 2px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.dashboard_navbar_userName {
  font-family: Roboto;
  font-size: 17px;
  line-height: 18px;
  font-weight: 600;
  text-align: left;
  display: block;
}

.dashboard_navbar_panel {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.dashboard_navbar_user_admin_text {
  padding-left: 10px;
}

.dashboard_navbar_drop_icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 2px;
}

.navbar_dropdown_menu {
  margin-top: 60px !important;
}

.navbar_dropdown_icon {
  color: #565353;
}

.navbar-right-menu-mannual {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
}

.dashboard_navbar_notification_link {
  position: relative;
  animation: pendulum 2s linear infinite;
}

.dashboard_navbar_notification_icon {
  position: relative;
}

.dashboard_navbar_notification_nadge {
  position: absolute;
  top: 14px;
  right: 13px;

  background-color: red;
  color: white;
  font-weight: bold;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 10px;
}


@keyframes pendulum {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(10deg);
  }

  50% {
    transform: rotate(0deg);
  }

  75% {
    transform: rotate(-10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.LOgo_Image {
  cursor: pointer;
  width: 37px;
  margin: 5px;


}



.logo_crmtext {
  background: #fff;
  display: flex;
  width: 100%;
  font-size: 26px;
  font-weight: 700;
  color: #000;
  text-decoration: none !important;
  height: 68px;
  align-items: center;
}

.logo_crmtext span {
  color: #ff7006;
}


.dashboard_sidebar_main_div.shrink.sidebar_hide_smallScreen img.LOgo_Image {
  display: block !important;
}



.dashboard_navbar ul.dropdown-menu.navbar_dropdown_menu.show {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  border: 1px solid #e9e7e7;
}

/* Sidebar Toggler */
.sidebar_toggle_icon {
  cursor: pointer;
  width: 30px;
  height: 30px;
  color: rgba(145, 155, 250, 1);
}





.sidebar_container_fluid {
  max-height: calc(100vh - 7vh);

}

.sidebar_container {
  max-height: calc(100vh - 7vh);

}

.lead_dashboard_link>ul {
  border-radius: 8px;
}

.lead_dashboard_link:hover>ul>li {
  line-height: 30px;
  font-size: 15px;
  font-weight: 400;
  list-style: none;
  padding: 2px 10px;
  cursor: pointer;
  border-radius: 5px;
}

.lead_dashboard_link:hover>ul>li:hover {
  background-color: #b9bcd8;
}

.dashboard_sidebar_main_div {
  width: 230px;
  height: 100vh;
  overflow-y: auto;
  background-color: #ECEEFF;
  transition: all .5s;
}

.dashboard_sidebar_main_div::-webkit-scrollbar {
  display: none;
}

.dashboard_sidebar_mainDiv {

  height: calc(100vh - 7vh);
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.sidebar_navItems {
  font-family: Roboto;
  /* font-size: 18px;
  font-weight: 500; */
  text-align: left;
  color: rgba(215, 219, 255, 1);
  transition: all .5s;
  margin: 0 0 4px;
}

.dashboard_sidebar_navItem_dropDown {
  line-height: 20px;
  background: linear-gradient(175.82deg, #7583ff 0%, #5869ff 100%);

}

.dashboard_sidebar_navItem_dropDown>li>a {
  color: rgba(215, 219, 255, 1);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
}

.sidebar_navItem_icon {
  cursor: pointer;

  font-size: 18px;
  margin: 1px 7px 5px 0px;
}

.sidebar_navItem_text {

  cursor: pointer;
  font-size: 15px;
}

.sidebar_logout_btn {
  width: 180px;
  height: 50px;
  opacity: 1;
  border-radius: 7px;

  background: linear-gradient(93.49deg, #7c8aff 1.57%, #6777ff 103.21%);
  /* margin: 4.4vh 0 0 -5%; */
  margin-top: -40px;
}

.sidebar_logout_btn:hover {
  background: linear-gradient(93.49deg, #374af7 1.57%, #5868f7 103.21%);
}

.sidebar_logout_icon {
  cursor: pointer;
  color: rgba(215, 219, 255, 1);
}

.sidebar_logout_text {
  color: rgba(215, 219, 255, 1);
  padding-left: 10px;
}

.sidebar_navItem_active {
  color: white;
}

.sidebar_hamburger_icon {
  color: rgba(215, 219, 255, 1);
  width: 35px;
  height: 35px;
  border: 3px solid #ff6e3f;
  border-radius: 50%;
  background-color: white;
  color: #ff6e3f;
  margin-left: 220px;
  cursor: pointer;
  gap: 0px;

}

.dashboard_sidebar_main_div.shrink {
  width: 60px;
  transition: all .5s;
}

.dashboard_sidebar_main_div.shrink .dashboard_sidebar_mainDiv {
  padding: 30%;
}

.dashboard_sidebar_main_div.shrink .sidebar_navItem_text {
  display: none;
}




.dashboard_sidebar_main_div.shrink .sidebar_hamburger_icon {
  margin-left: 18px;
}

.sidebar_navItem_active .sidebar_navItem_text {
  display: inline;
  /* Display text for active item */
}

.sidebar_shrink_logout_btn {
  cursor: pointer;
  color: rgb(132, 140, 201);
  font-size: larger;
}

.dashboard_sidebar_main_div .shrink .sidebar_shrink_logout_btn {
  cursor: pointer;
  margin: 10.3vh 0 2vh -5%;
  color: rgba(215, 219, 255, 1);
}

.sidebar_navbar_mainDiv {
  padding-right: 30px;
}

.sidebar_navbar_right_menu {
  margin-top: 10vh;
  width: 25px;
  height: 25px;
  color: white;
}

.sidebar_user_name {
  width: 100px;
}

.dashboard_sidebar_main_div.shrink .sidebar_navbar_right_menu {
  display: none;
}

.dashboard_sidebar_main_div.shrink .sidebar_navbar_icon {
  display: none;
}


.calls-dropdown {
  display: none;
}

.sidebar_close_btn {
  /* color: white;
  float: inline-end;
  margin-right: 10px; */
  color: #000;
  float: inline-end;
  margin-right: 2px;
  position: relative;
  margin-top: -42px;
}



/* Show Hide Sidebar In small Screen */






.dashboard_leads_main_container {
  height: calc(100% - 10px);
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 235, 249, 1);
  box-shadow: 2px 3px 30px 0px rgba(64, 123, 255, 0.12);
  border-radius: 20px;
}

.filter-sidebar {
  padding: 20px 0 0 0;
}

.filter_save:hover {
  background-color: #989999;
}

.sidebar {
  padding: 20px;
  background-color: #f0f0f0;
}

.search-container {
  position: relative;
  margin-bottom: 20px;
  height: 46px;
  border-radius: 8px;
  border: 1px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

input[type="text"] {
  border-radius: 4px;
}

.search-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  width: 45px;
  height: 25px;
  color: rgba(255, 112, 6, 1);
}


.filter-container_radio {
  padding: 10px 0px 10px 7px;
  width: 100%;

}

.filter-container_detail {
  position: relative;
  background-color: white;
  padding: 4px 0px 4px 7px;
  border: 1px solid rgba(161, 160, 160, 0.438);
  border-radius: 8px;
  width: 90%;
}

.customrangefilter {
  display: flex;
  align-items: center;
  height: 33px;
  width: 90%;
  padding: 4px 0px 4px 7px;
  outline: none;
  overflow: hidden;
  border: 1px solid rgba(161, 160, 160, 0.438);
  margin-bottom: 0px;
  border-radius: 8px;
}

.filter-container input {
  height: 33px;
  width: 90%;
  padding: 4px 0px 4px 7px;
  border: 1px solid rgba(161, 160, 160, 0.438);
  margin-bottom: 0px;
  border-radius: 8px;
}

.filter-container select {
  display: flex;
  align-items: center;
  width: 90%;
  padding: 5px 5px;
  outline: none;
  border-radius: 8px;
  border: 1px solid rgba(103, 119, 255, 1);
}

.filter-container h4 {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
}

.form-check {
  margin-bottom: 10px;
}

.form-check-label {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  color: rgb(56, 56, 56);
}

.filter-btn {
  max-width: 128px;
  border-radius: 8px;
  border: 1px solid rgba(194, 200, 212, 1);
  background: linear-gradient(180deg, #AEB4C4 0%, #B4B9C9 55%, #ADB3C2 100%);
  box-shadow: 2px 3px 26px 0px rgba(64, 123, 255, 0.11);
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  color: rgba(253, 254, 254, 1);
  text-align: center;
  padding: 6px 20px;
}



.filter_sidebar_mainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(244, 247, 255);
  height: 72vh;
  width: 100%;
  margin-left: -12px;
  padding: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  max-width: 220px;
}

.lead_filter_radio_btn {
  cursor: pointer;
}

.leads_filter_input_field {
  padding: 8px !important;
  border-radius: 10px !important;
  border: 1px solid rgba(208, 209, 210, 1);
}



.leads_filter_input_field:focus {
  outline: none;
}

.LeadRightSectionTable_body::-webkit-scrollbar {
  display: none;
}

.log_data_displayer::-webkit-scrollbar {
  display: none;
}

.log_head {
  font-size: 16px;
  font-weight: 500;
}


.rowd {
  margin: 0 -14px;
}

/* Right Leads */

.dashboard_content_wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.dashboard_leads_btn_mainDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;


}



.dashboard_leads_table_div {
  flex-grow: 1;
  /* max-height: 100%; */
  height: 100%;
}


textarea#lead_remark {
  border: none;
  border-radius: 5px;
  resize: none;
  outline: none;
}

button.lead_update_status_button {
  border: none;
  padding: 3px 14px;
  border-radius: 7px;
  background: linear-gradient(180deg, #5869FF 0%, #394EFF 52%, #394EFF 100%);
  color: white;
  float: right;
}




.table_log {
  width: 95%;
  max-height: calc(100%);
}

.dashboard_rightLeads_main_container {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 235, 249, 1);
  box-shadow: 2px 3px 30px 0px rgba(64, 123, 255, 0.12);
  border-radius: 20px;
}

.dashboard_rightLeads_main_containers {
  height: 100%;
  background-color: rgba(255, 255, 255, 1);
}

.dashboard_leads_btns_div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
}


.actionbtn {
  padding: 0px 5px;
}

.leads_verify_btn button.default-btn svg {
  margin-right: 5px;
  margin-top: -2px;
}


.dashboard_leads_print_btn {
  width: 50px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid rgba(194, 200, 212, 1);
  box-shadow: 2px 3px 26px 0px rgba(64, 123, 255, 0.11);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.asignlead_border {
  border-bottom: 1px solid rgba(194, 200, 212, 1);

}



.dashboard_leads_create_link {
  color: white;
  text-decoration: none;
}

.dashboard_leads_pagination_div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;
}

.company_page_size {
  width: 39px;
  height: 30px;
  outline: none;
  border: 1px solid rgba(97, 97, 97, 0.397);
  border-radius: 4px;
  padding: 4px;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  color: grey;
} 



.dashboard_leads_pagination_pageItem {
  margin: 0 10px;
  cursor: pointer;
  height: 38px;
  overflow: hidden;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
}


.applybtn button {

  border: 1px solid rgba(103, 119, 255, 1);
  border-Radius: 5px;
  padding: 2px 11px;
}

.applybtn button:hover {
  background-color: rgba(103, 119, 255, 1);
  color: #f0f0f0;
}



li.page-item.active.dashboard_leads_pagination_pageItem span.page-link {
  background: #5F6FFF !important;
  color: white !important;

}

li.page-item.dashboard_leads_pagination_pageItem span.page-link {
  background: #E4E8EB !important;
  color: #808487 !important;

}

.dashboard_leads_pagination_pageItem span.page-link {
  color: #5F6FFF !important;
}


.dashboard_leads_assign_btn {
  border: 1px solid #d6b91d !important;
  color: #bca110 !important;
}



/* Lead Status Text Color */
.lead-table-leadStatus-lead {
  color: red !important;
  font-weight: 500 !important;
}

.lead-table-leadStatus-contacted {
  color: rgb(53, 212, 53) !important;
  font-weight: 500 !important;
}

.lead-table-leadStatus-deal {
  color: rgba(103, 119, 255, 1) !important;
  font-weight: 500 !important;
}

.lead-table-contact-action-icon {
  color: #ff7006;
  font-size: 20px !important;
}

.leads_table_id_text {
  color: rgba(103, 119, 255, 1);

  text-align: center;
}







.wrap-check-29 {
  position: relative;
}

.wrap-check-29>svg {
  position: absolute;
  top: -130%;
  left: -170%;
  width: 110px;
  pointer-events: none;
}

.wrap-check-29 * {
  box-sizing: border-box;
}

.wrap-check-29 input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0;
}

.wrap-check-29 input[type="checkbox"]:focus {
  outline: 0;
}

.wrap-check-29 .cbx {
  width: 24px;
  height: 24px;
  top: calc(50vh - 12px);
  left: calc(50vw - 12px);
}

.wrap-check-29 .cbx input {
  position: absolute;
  top: 3px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 1px solid #bfbfc0;
  border-radius: 25%;
}

.wrap-check-29 .cbx label {
  width: 17px;
  height: 17px;
  background: none;
  border-radius: 20%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-filter: url("#goo-12");
  filter: url("#goo-12");
  transform: trasnlate3d(0, 0, 0);
  pointer-events: none;
}

.wrap-check-29 .cbx svg {
  position: absolute;
  top: 1px;
  left: 2px;
  z-index: 1;
  pointer-events: none;
}

.wrap-check-29 .cbx svg path {
  stroke: #fff;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 19;
  stroke-dashoffset: 19;
  transition: stroke-dashoffset 0.3s ease;
  transition-delay: 0.2s;
}

.wrap-check-29 .cbx input:checked+label {
  animation: splash-12 0.6s ease forwards;
}

.wrap-check-29 .cbx input:checked+label+svg path {
  stroke-dashoffset: 0;
}

.filter_div {
  display: flex;
  flex-direction: row;
}



@-moz-keyframes splash-12 {
  40% {
    background: rgba(88, 105, 255, 1);
    box-shadow: 0 -18px 0 -8px rgba(88, 105, 255, 1),
      16px -8px 0 -8px rgba(88, 105, 255, 1),
      16px 8px 0 -8px rgba(88, 105, 255, 1), 0 18px 0 -8px rgba(88, 105, 255, 1),
      -16px 8px 0 -8px rgba(88, 105, 255, 1),
      -16px -8px 0 -8px rgba(88, 105, 255, 1);
  }

  100% {
    background: rgba(88, 105, 255, 1);
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@-webkit-keyframes splash-12 {
  40% {
    background: rgba(88, 105, 255, 1);
    box-shadow: 0 -18px 0 -8px rgba(88, 105, 255, 1),
      16px -8px 0 -8px rgba(88, 105, 255, 1),
      16px 8px 0 -8px rgba(88, 105, 255, 1), 0 18px 0 -8px rgba(88, 105, 255, 1),
      -16px 8px 0 -8px rgba(88, 105, 255, 1),
      -16px -8px 0 -8px rgba(88, 105, 255, 1);
  }

  100% {
    background: rgba(88, 105, 255, 1);
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@-o-keyframes splash-12 {
  40% {
    background: rgba(88, 105, 255, 1);
    box-shadow: 0 -18px 0 -8px rgba(88, 105, 255, 1),
      16px -8px 0 -8px rgba(88, 105, 255, 1),
      16px 8px 0 -8px rgba(88, 105, 255, 1), 0 18px 0 -8px rgba(88, 105, 255, 1),
      -16px 8px 0 -8px rgba(88, 105, 255, 1),
      -16px -8px 0 -8px rgba(88, 105, 255, 1);
  }

  100% {
    background: rgba(88, 105, 255, 1);
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@keyframes splash-12 {
  40% {
    background: rgba(88, 105, 255, 1);
    box-shadow: 0 -18px 0 -8px rgba(88, 105, 255, 1),
      16px -8px 0 -8px rgba(88, 105, 255, 1),
      16px 8px 0 -8px rgba(88, 105, 255, 1), 0 18px 0 -8px rgba(88, 105, 255, 1),
      -16px 8px 0 -8px rgba(88, 105, 255, 1),
      -16px -8px 0 -8px rgba(88, 105, 255, 1);
  }

  100% {
    background: rgba(88, 105, 255, 1);
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}


.filters {
  width: 100%;
  margin-bottom: 10px;
}

.filterBtn {
  margin-left: 5px;
}


.account_view_details_main_container {
  background-color: rgba(255, 255, 255, 1);
}



.assign_lead_table1_btn {
  width: 100px;
  height: 40px;
  border-radius: 12px;
  border: 1px;
  background-color: rgba(88, 105, 255, 1);
  border: 1px solid rgba(221, 221, 221, 1);
  box-shadow: 2px 3px 18px 0px rgba(147, 130, 254, 0.12);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  margin-top: 20px;
}

.assign_leads_table {
  border: 1px solid silver;
}


.remove_arrow_create_call_btn::after {
  display: none;
}


.contact_view_details_costumer_table_th {
  font-family: Roboto;
  font-size: 17px;
  font-weight: 400;
  line-height: 19.92px;
  text-align: left;
  color: rgba(128, 128, 128, 1);

}

.mannual-table-responsive {
  width: 100%;
}

.companydetail_row {
  display: flex;
  flex-direction: column;
}

.contact_view_details_costumer_table_th {
  font-family: Roboto;
  font-size: 17px;
  font-weight: 400;
  line-height: 19.92px;
  text-align: left;
  color: rgba(128, 128, 128, 1);

}

.mannual-table-responsive {
  width: 100%;
}

.companydetail_row {
  display: flex;
  flex-direction: column;
}


.dashboard_create_lead_main_container {
  padding: 30px;
  margin-bottom: 10px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 235, 249, 1);
  box-shadow: 2px 3px 30px 0px rgba(64, 123, 255, 0.12);
  border-radius: 20px;
}

.create_user_img {
  width: 130px;
  height: 130px;
  border: 3px;
  border: 3px solid rgba(248, 201, 167, 1);
  border-radius: 50%;
  margin-right: 20px;
}

.create_change_upload_file_btn {
  width: 128px;
  height: 50px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(194, 200, 212, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}


.create_change_upload_file_btn>label {
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  line-height: 19.92px;
  text-align: center;
  color: rgba(107, 112, 125, 1);
  cursor: pointer;
}

.create_lead_remove_img_btn {
  width: 150px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid rgba(221, 221, 221, 1);
  background-color: rgba(88, 105, 255, 1);
  box-shadow: 2px 3px 18px 0px rgba(147, 130, 254, 0.12);
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  line-height: 19.92px;
  text-align: center;
  color: rgba(253, 254, 254, 1);
  margin: 10px;
}

.create_lead_user_img_div {
  display: flex;
  align-items: center;
  padding: 30px;
  flex-wrap: wrap;
}

.create_leads_use_img_btns {
  display: flex;
  flex-wrap: wrap;
}

/* From */
.createLeadInput input[type="text"],
.createLeadInput input[type="email"],
.createLeadInput input[type="tel"] {
  box-sizing: border-box;
}


button.default-btn {
  border: none;
  background: linear-gradient(180deg, #AEB4C4 0%, #B4B9C9 55%, #ADB3C2 100%);
  font-size: 17px;
  color: white;
  border-radius: 8px;
  padding: 6px 10px;
}


.actionbtn .default-btn {
  background: linear-gradient(180deg, #74C622 0%, #68B61A 55%, #65B712 100%);

}

.createbtn .default-btn {
  background: linear-gradient(180deg, #AEB4C4 0%, #B4B9C9 55%, #ADB3C2 100%);
}


.create_call_form {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid rgba(128, 127, 127, 0.24);
  border-radius: 5px;
}

.datalist_dropdown {
  position: absolute;
  left: 0px;
  top: 95%;
  width: 100%;
  background-color: rgb(250, 238, 238);
  border-radius: 0px 0px 5px 5px;
  z-index: 111;
  padding: 15px;
}



.createLeadInput {
  margin: -10px 0 40px 0;
  position: relative;
  min-width: 220px;
}


.Upload_image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}


.Upload_image_button {
  border: 1px solid grey;
  padding: 12px 16px;
  max-height: 50px;
  cursor: pointer;
  border-radius: 5px;
}

.Upload_image_box {
  width: 150px;
  height: 150px;
  border: 1px dashed grey;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}

.form-control {
  padding-right: 2.5rem;
  appearance: none;
  font-size: 12px;
}

.create_lead_form_input {
  margin-top: 4px;
  color: #686666;
}

.create_lead_form_input::placeholder {
  color: #808080;
  font-size: small;
}
.createLeadInput .create_lead_input_icon{
  top: 35px;
}
.updsate_lead_status{
  z-index: 1;
}
.create_lead_input_icon {
  position: absolute;
  top: 10px;
  right: 3%;
  color: rgba(115, 115, 115, 1);
}


.lead_iconfirst{

  top: 45% !important;

}

.create-lead-heading {
  display: flex;
  justify-content: space-between;
}

.create-lead-heading-right-para {
  font-family: Roboto;
  font-weight: 400;
  margin: 0px;
}


.create-lead-heading-span {
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
}

/* form section 2 */
.create_lead_section2_company_info {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;

}


.create_lead_section2_description {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;

}

.create_lead_section2_description_label {
  padding-left: 2px;
  margin-bottom: 8px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.75px;
  text-align: left;
  color: black;
}


/* Toast */
.create_lead_toast {
  margin-right: 18px;
  border-color: rgba(88, 105, 255, 1);
  color: white;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: left;
  background: linear-gradient(175.82deg, #7583ff 0%, #5869ff 100%);
}

.create_lead_toast_header {
  color: black;
}



.create_meeting_mainDiv {
  position: relative;
}

.create_meeting_collapseDiv {
  position: absolute;
  z-index: 999;
  background-color: rgb(249, 248, 248);
  width: 95%;
  border-radius: 10px;
  padding: 15px;
}


.dashboard_main_container {
  background-color: rgba(255, 255, 255, 1);
  padding: 15px 15px;
  border-radius: 12px;
}

/* Text Color Status */
.status-not-started {
  color: rgb(151, 84, 6) !important;
}

.status-success {
  background-color: rgb(11, 97, 211);
}

.status-completed {
  color: rgb(7, 128, 37) !important;
}

.status-waiting-for-input {
  color: rgb(161, 179, 5) !important;
}

.status-deffered {
  color: rgb(5, 189, 221) !important;
}

.status-in-progress {
  color: rgb(224, 43, 11) !important;
}

/* Text Color Stage */

.stage-lost {
  color: #5869ff !important;
}

.stage-need-analysis {
  color: rgba(222, 83, 250, 0.644) !important;
}

.stage-value-proposition {
  color: rgb(201, 181, 8) !important;
}

.stage-identify-decision-maker {
  color: #69c2e5 !important;
}

.negotitation {
  color: #ff7006 !important;
}

.stage-proposal {
  color: #c062b0 !important;
}

.stage-won {
  color: #2ec282 !important;
}

.dashboard_user_name {
  font-family: Roboto;
  font-size: 19px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  margin-bottom: -4px;
}

.dashboard_user_name2 {
  font-family: Roboto;
  font-size: 19px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  margin-bottom: 0px;
}

.dashboard_row1 {
  margin-top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.responsivetable_dashboard {
  width: 58%;
}

.piechart_responsive {
  width: 40%;
}

.dashboard_card_mainDiv {
  display: flex;
  justify-content: center;
}

.dashboard_mainDiv_card {
  width: 210px;
  height: 140px;
  margin-right: 10px;
  padding-top: 1 0px;
}

.dashboard_mainDiv_single_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.dashboard_card_icon_div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border: 1.5px;
  border-radius: 50%;
  background-color: white;
}

.dashboard_card_icon {
  width: 36.91px;
  height: 24.81px;
}

.dashboard_card_text {
  margin-bottom: -3px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: rgba(88, 88, 88, 1);
}

.dashboard_card_text_link {
  text-decoration: none;
  color: inherit;
}

.dashboard_card_number {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  color: rgba(25, 42, 62, 1);
}

.dashboard_firstCard_icon {
  color: rgba(105, 194, 229, 1);
}

.dashboard_mainDiv_firstCard {
  background-color: rgba(186, 235, 255, 1);
  border: 1px solid rgba(142, 212, 239, 1);
}

.dashboard_secondCard_icon {
  color: rgba(43, 211, 106, 1);
}

.dashboard_mainDiv_secondCard {
  background-color: rgba(200, 255, 220, 1);
  border: 1px solid rgba(123, 220, 158, 1);
}

.dashboard_thirdCard_icon {
  color: rgba(255, 112, 6, 1);
}

.dashboard_mainDiv_thirdCard {
  background-color: rgba(255, 221, 211, 1);
  border: 1px solid rgba(253, 189, 170, 1);
}

.dashboard_fourthCard_icon {
  color: rgba(88, 105, 255, 1);
}

.dashboard_mainDiv_fourthCard {
  background-color: rgba(229, 232, 255, 1);
  border: 1px solid rgba(171, 195, 248, 1);
}

/* Dashboard Section 1 Table*/
.dashboard_section1_table>h4 {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  color: #1F1F1F;
  padding-left: 15px;

}

.dashboard_section1_thead {
  background-color: rgba(255, 241, 236, 1) !important;
}

.dashboard_table_mainDiv {
  height: 100%;

  border-radius: 10px;
  box-shadow: 7px 3px 30px 4px #004fff1f;
  margin-top: 17px;
}

.dashboard_table_main_heading {
  padding: 15px 0px;
}


.mytable thead tr th {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  color: black;
  background-color: #F4F4F4;
  padding: 15px 17px;
  line-height: 22px;
}


.dashtitle {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  color: #1F1F1F;
  margin: 0 0 20px;
  padding: 0 20px;
}

.table_maincard {
  box-shadow: 2px 3px 30px 0px #0d16281f;
  padding: 20px 0 20px;
  border-radius: 8px;
  border: 1px solid rgba(229, 235, 249, 1);
}

.mytable tr td {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: rgb(43, 42, 42);
  padding: 10px 17px;
}

.th-design {
  vertical-align: baseline;

}

.dashboard_section1_table_edit_button {
  width: 38px;
  height: 38px;
  color: rgba(64, 123, 255, 1);
  cursor: pointer;
}

.dashboard_section1_table_editBtn {
  color: rgba(255, 112, 6, 1);
  margin-right: 10px;
}

.dashboard_section1_table_deleteBtn {
  color: rgba(255, 112, 6, 1);
  margin-right: 10px;
  background-color: #FED8BC;
  padding: 2px;
  border-radius: 10px;
}

/* Section 2 */     

.dashboard_section2_chart_mainDiv {
  text-align: center;
}

.dashboard_section2_chart_div {
  height: 300px;
  width: 300px;
  position: relative;
  display: inline-block;
}

.dashboard_section2_row2 {
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 235, 249, 1); 
  box-shadow: 2px 3px 30px 0px rgba(64, 123, 255, 0.12);
  border-radius: 8px;
}

/* Section 3 */


/* Same Css For Every Dashboard Page */
.Link-button {
  padding: 1px;
  text-decoration: none;
  color: #737373;

  font-weight: 500;
}

.dashboard_sidebar_mainDiv a {
  display: block;
  border-radius: 10px;
  text-decoration: none;
  color: #1b1b1b;
  transition: all .5s;
  font-weight: 400;
  padding: 8px 12px;
  /* width: 200px; */

}

.dashboard_sidebar_mainDiv a.active,
.dashboard_sidebar_mainDiv a:hover {
  color: white;
  background: linear-gradient(180deg, #394EFF 0%, #3341B9 100%);
}

.dashboard_sidebar_main_div.shrink.sidebar_hide_smallScreen a.NavLink-button {
  padding: 10px 0px;
  background: transparent;
  color: black;
}


.dashboard_leadView_details_heading {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: rgba(54, 54, 54, 1);
  margin-bottom: -10px;
}

.dashboard_leadView_details_heading_second {
  font-family: monospace;
  font-size: 16px;
  font-weight: 800;
}

.lead_costumer_details_firstRow_left_imgDiv {
  width: 150px;
  height: 150px;
  border: 3px solid rgba(248, 201, 167, 1);
  border-radius: 50%;
  margin-bottom: 20px;
}

.lead_costumer_details_firstRow_left_img {
  height: 100%;
  border-radius: 50%;
}

/* .lead_view_details_table_td {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
  line-height: 18.75px;
  color: rgb(104, 103, 103);
} */


.lead_view_details_costumer_name_email {
  width: 150px;
  height: 150px;
}

.lead_view_details_costumer_name {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  line-height: 0px;
  text-align: center;
}

.lead_view_details_costumer_email {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: rgba(116, 116, 116, 1);
  text-align: center;
}

/* Row 2 */
.dashboard_leadView_company_details_heading {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
  margin-bottom: 8px;

}

/* Description */
.lead_view_details_description {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

/* Btns  */
.view_leads_details_btn_div>.leads_reject_btn {
  width: 138px;
  height: 43px;
  border-radius: 8px;
  border: 1px solid rgba(194, 200, 212, 1);
  background-color: #d84b21;
  box-shadow: 2px 3px 26px 0px rgba(64, 123, 255, 0.11);
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  line-height: 19.92px;
  text-align: center;
  color: rgba(253, 254, 254, 1);
  margin-right: 10px;
  margin-bottom: 10px;
}

.view_leads_details_btn_div>.leads_verify_btn {
  width: 128px;
  height: 43px;
  border-radius: 8px;
  border: 1px solid rgba(194, 200, 212, 1);
  background-color: #7fcf2f;
  box-shadow: 2px 3px 26px 0px rgba(64, 123, 255, 0.11);
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  line-height: 19.92px;
  text-align: center;
  color: rgba(253, 254, 254, 1);
  margin-right: 10px;
  margin-bottom: 10px;
}



.leaddetailinfo{
  vertical-align: middle !important;
}


/* Same Css All Leads */
.Link-button-leads {
  text-align: center;
  text-decoration: none;
  color: rgba(115, 129, 130, 1);
}

.lead_update_status_button:hover {
  background-color: #8fa3aa;
}

.lead_status_update {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 999;
  position: absolute;
  padding: 20px 11px 20px 18px;
  right: 10px;
  width: 300px;
  height: 248px;
  border: 1px solid rgb(212, 213, 214);
  background: rgb(198 202 213);
  border-radius: 5px;
}


.Link-button-leads svg {
  font-size: 20px !important;
}


.LeadRightSectionTable_body .table-responsive {
  box-sizing: border-box;
  overflow: auto;
}


.LeadRightSectionTable_body .table-responsive thead {
  position: sticky;
  top: 0;
  z-index: 1;

}

.actionbtn .default-btn ul.dropdown-menu.show {
  border: 1px solid #edeaea;
  box-shadow: 0px 1px 15px 3px #e5e5e5;
}

.filter_div {
  display: flex;
  flex-direction: row;
}


@-moz-keyframes splash-12 {
  40% {
    background: rgba(88, 105, 255, 1);
    box-shadow: 0 -18px 0 -8px rgba(88, 105, 255, 1),
      16px -8px 0 -8px rgba(88, 105, 255, 1),
      16px 8px 0 -8px rgba(88, 105, 255, 1), 0 18px 0 -8px rgba(88, 105, 255, 1),
      -16px 8px 0 -8px rgba(88, 105, 255, 1),
      -16px -8px 0 -8px rgba(88, 105, 255, 1);
  }

  100% {
    background: rgba(88, 105, 255, 1);
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@-webkit-keyframes splash-12 {
  40% {
    background: rgba(88, 105, 255, 1);
    box-shadow: 0 -18px 0 -8px rgba(88, 105, 255, 1),
      16px -8px 0 -8px rgba(88, 105, 255, 1),
      16px 8px 0 -8px rgba(88, 105, 255, 1), 0 18px 0 -8px rgba(88, 105, 255, 1),
      -16px 8px 0 -8px rgba(88, 105, 255, 1),
      -16px -8px 0 -8px rgba(88, 105, 255, 1);
  }

  100% {
    background: rgba(88, 105, 255, 1);
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@-o-keyframes splash-12 {
  40% {
    background: rgba(88, 105, 255, 1);
    box-shadow: 0 -18px 0 -8px rgba(88, 105, 255, 1),
      16px -8px 0 -8px rgba(88, 105, 255, 1),
      16px 8px 0 -8px rgba(88, 105, 255, 1), 0 18px 0 -8px rgba(88, 105, 255, 1),
      -16px 8px 0 -8px rgba(88, 105, 255, 1),
      -16px -8px 0 -8px rgba(88, 105, 255, 1);
  }

  100% {
    background: rgba(88, 105, 255, 1);
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}

@keyframes splash-12 {
  40% {
    background: rgba(88, 105, 255, 1);
    box-shadow: 0 -18px 0 -8px rgba(88, 105, 255, 1),
      16px -8px 0 -8px rgba(88, 105, 255, 1),
      16px 8px 0 -8px rgba(88, 105, 255, 1), 0 18px 0 -8px rgba(88, 105, 255, 1),
      -16px 8px 0 -8px rgba(88, 105, 255, 1),
      -16px -8px 0 -8px rgba(88, 105, 255, 1);
  }

  100% {
    background: rgba(88, 105, 255, 1);
    box-shadow: 0 -36px 0 -10px transparent, 32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent, 0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent, -32px -16px 0 -10px transparent;
  }
}


.dashboard_create_lead_main_container form {
  padding-top: 16px;
}

.participants_name {
  display: flex;
  flex-wrap: wrap;
}

.actionleadbtn .default-btn {

  background: linear-gradient(180deg, #74C622 0%, #68B61A 55%, #65B712 100%);

}

.actionleadbtn {
  padding: 0px 0px 0px 4px;
}

.create_leadbtn .default-btn {
  background: linear-gradient(180deg, #74C622 0%, #68B61A 55%, #65B712 100%);
}

.created-user {
  background-color: rgba(255, 255, 255, 1);
  border-top: 1px solid rgba(229, 235, 249, 1);
  border-radius: 10px;
  box-shadow: 2px 3px 30px 0px rgba(64, 123, 255, 0.12);
}


.contactcreatebtn .default-btn {
  background: linear-gradient(180deg, #74C622 0%, #68B61A 55%, #65B712 100%);
  margin: 0px 5px;
}
.contactcreatebtn{
  position: relative;
}
.dropdown-menu{
  /* left: -40px !important; */
  width: fit-content;
}
.modal.show .modal-dialog{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.error_div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}

.error_page_btn {
  width: 213px;
  height: 52px;
  top: 885px;
  border-radius: 12px;
  border: 1px;
  border: 1px solid #7381ff;
  background: #5869ff;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  color: white;
}

.error_page_btn_link {
  text-decoration: none;
  color: inherit;
}

.error_page_heading {
  font-family: Roboto;
  font-size: 30px;
  font-weight: 700;
  line-height: 35.16px;
  text-align: center;
}

.error_page_text>span {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 700;
  color: #5869ff;
}


.forgot_left_mainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;
  border-radius: 10px;
  background-color: #f7f7f7;
}


.landingPageFirstPart {
  height: 100vh;
  width: 100%;
  padding: 50px 0;
  background-image: url(./images/landingPage.png);
}

.landingPageFirstPartLoginBtnDiv {
  /* width: 1530px; */
  height: 70px;
  background-color: #edf8ff;
  box-shadow: 2px 3px 22px 0px #c7eaf057;
  margin: 0 auto;
  border-radius: 70px;
}

.landingPageFirstPartLoginBtnDivLogo {
  position: relative;
  left: 20px;
  top: 10px;
}

.landingPageFirstPartLoginBtnDiv2 {
  position: relative;
  right: 20px;
  top: 10px;
}

.landingPageFirstPartLoginBtn {
  background: linear-gradient(101.13deg, #0e165a -53.88%, #1e2db4 105.8%);
  box-shadow: 0px 3px 22px 0px #17238f42;
  width: 141.61px;
  height: 52px;
  border-radius: 60px;
  color: #ffffff;
  border: 3px solid white;
}

.landingPageTitleDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 180px;
  position: relative;
  top: 25%;


}

.landingPageTitleDivIcon1 {
  position: relative;
  left: -22%;
}

.landingPageTitleDivIcon2 {
  position: relative;
  top: 16%;
  text-align: center;
}

.landingPageTitleDivIcon3 {
  position: relative;
  left: 55%;
  top: 22%;
}

.landingPageTitleDivPara {
  font-family: Aleo;
  font-size: 64px;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
}

.landingPageTitleDivSpan1 {
  color: #ff6e3f;
}

.landingPageTitleDivSpan2 {
  color: #5869ff;
}

.landingPageSlogan>p {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
}

.landingPageTryBtn {
  width: 200.22px;
  height: 60px;
  background: linear-gradient(101.13deg, #0e165a -53.88%, #1e2db4 105.8%);
  box-shadow: 0px 3px 22px 0px #17238f42;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 600;
  line-height: 23.44px;
  text-align: center;
  border-radius: 60px;
  color: #ffffff;
  border: none;
  margin-bottom: 1px;
  position: relative;
  margin-top: 67px;
  padding: 10px;
}

/* Second Part */
.landingPageSecondPart {
  margin: 50px auto;
  border-radius: 22px;
  background-color: #ecf2ff;
}

.landingPageSecondPartHeading {
  text-align: center;
  padding: 20px 0 0 0;
}

.landingPageSecondPartPara {
  text-align: center;
  padding: 0 80px 20px 80px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.landingPageSecondPartPara>span {
  font-size: x-large;
  font-weight: bold;
  color: #5869ff;
}

/* Third Part Our Features */
.landingPageThirdPart {
  margin: 100px auto;
}

.landingPageThirdPartCardIcon {
  margin: 15px auto;
  color: #9cbbff;
}

.landingPageThirdPartCard {
  box-shadow: 2px 3px 12px 0px #5869ff1c;
  border: none !important;
}

/* Fourth Part Image Our Costumer */
.landingpageFourthPartMainDiv {
  border-radius: 22px;
  background-image: url(./images/ourCostumer.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 100px;
}

.landingpageFourthPartMainDiv::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 22px;
}

.landingpageFourthPartMainDivHeading {
  font-family: Roboto;
  font-size: 45px;
  font-weight: 600;
  line-height: 40px;
  text-align: center;
  color: rgb(255, 255, 255);
}


.landingpageFourthPartCarouselItemHeading {
  color: white !important;
}

.landingpageFourthPartCarouselDiv {
  padding-top: 80px !important;
  padding-bottom: 20px !important;
}

.landingpageFourthPartCarouselItemPara {
  color: white !important;
}

/* Testimonial css */

.costum-carousel {
  cursor: pointer;
}

.special_text_color {
  color: var(--special_text_color);
  letter-spacing: var(--special_letter_spacing);
  font-weight: 600;
}

.landingpageFourthPartCarouselDivHeading {
  color: white;
}

.landingpageFourthPartCarouselDivHeading {
  color: white;
}



/* Footer */
.landingPageFooter {
  background: linear-gradient(#333c8d, #3647df);
  background-color: #333c8d;
}



.landingPageFooterUpperDiv {
  display: flex !important;
  justify-content: space-around;
}

.landingPageFooterList li {
  list-style-type: none;
  margin: 10px 0 10px 0;
  text-align: start;
}

.landingPageLocationLink {
  text-decoration: none;
  color: white;
}


.loader {
  position: fixed;
  left: 50%;
  top: 50%;
  --w: 10ch;
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  letter-spacing: var(--w);
  width: var(--w);
  overflow: hidden;
  white-space: nowrap;
  text-shadow: calc(-1 * var(--w)) 0, calc(-2 * var(--w)) 0,
    calc(-3 * var(--w)) 0, calc(-4 * var(--w)) 0, calc(-5 * var(--w)) 0,
    calc(-6 * var(--w)) 0, calc(-7 * var(--w)) 0, calc(-8 * var(--w)) 0,
    calc(-9 * var(--w)) 0;
  animation: l16 2s infinite;
}

.loader:before {
  content: "Loading...";
}

@keyframes l16 {
  20% {
    text-shadow: calc(-1 * var(--w)) 0, calc(-2 * var(--w)) 0 red,
      calc(-3 * var(--w)) 0, calc(-4 * var(--w)) 0 #ffa516,
      calc(-5 * var(--w)) 0 #63fff4, calc(-6 * var(--w)) 0,
      calc(-7 * var(--w)) 0, calc(-8 * var(--w)) 0 green, calc(-9 * var(--w)) 0;
  }

  40% {
    text-shadow: calc(-1 * var(--w)) 0, calc(-2 * var(--w)) 0 red,
      calc(-3 * var(--w)) 0 #e945e9, calc(-4 * var(--w)) 0,
      calc(-5 * var(--w)) 0 green, calc(-6 * var(--w)) 0 orange,
      calc(-7 * var(--w)) 0, calc(-8 * var(--w)) 0 green, calc(-9 * var(--w)) 0;
  }

  60% {
    text-shadow: calc(-1 * var(--w)) 0 lightblue, calc(-2 * var(--w)) 0,
      calc(-3 * var(--w)) 0 #e945e9, calc(-4 * var(--w)) 0,
      calc(-5 * var(--w)) 0 green, calc(-6 * var(--w)) 0,
      calc(-7 * var(--w)) 0 yellow, calc(-8 * var(--w)) 0 #ffa516,
      calc(-9 * var(--w)) 0 red;
  }

  80% {
    text-shadow: calc(-1 * var(--w)) 0 lightblue, calc(-2 * var(--w)) 0 yellow,
      calc(-3 * var(--w)) 0 #63fff4, calc(-4 * var(--w)) 0 #ffa516,
      calc(-5 * var(--w)) 0 red, calc(-6 * var(--w)) 0,
      calc(-7 * var(--w)) 0 grey, calc(-8 * var(--w)) 0 #63fff4,
      calc(-9 * var(--w)) 0;
  }
}



/* HTML: <div class="loader"></div> */
.loader2 {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 20px;
  background: radial-gradient(circle closest-side, #000 94%, #0000) right/calc(200% - 1em) 100%;
  animation: l24 1s infinite alternate linear;
}

.loader2::before {
  content: "Loading...";
  line-height: 1em;
  color: #0000;
  background: inherit;
  background-image: radial-gradient(circle closest-side, #fff 94%, #000);
  -webkit-background-clip: text;
  background-clip: text;
}

.uploader {
  width: fit-content;
  font-weight: bold;
  font-family: monospace;
  font-size: 20px;
  background: radial-gradient(circle closest-side, #000 94%, #0000) right/calc(200% - 1em) 100%;
  animation: l24 1s infinite alternate linear;
}

.uploader::before {
  content: "Uploading...";
  line-height: 1em;
  color: #0000;
  background: inherit;
  background-image: radial-gradient(circle closest-side, #fff 94%, #000);
  -webkit-background-clip: text;
  background-clip: text;
}

@keyframes l24 {
  100% {
    background-position: left;
  }
}


/* HTML: <div class="loader"></div> */
.loader3 {
  position: relative;
  top: 50%;
  left: 50%;
  width: 50px;
  --b: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, #f03355) content-box;
  --webkit-mask: repeating-conic-gradient(#0000 0deg,
      #000 1deg 20deg,
      #0000 21deg 36deg),
    radial-gradient(farthest-side,
      #0000 calc(100% - var(--b) - 1px),
      #000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: l4 1s infinite steps(10);
}

@keyframes l4 {
  to {
    transform: rotate(1turn);
  }
}


/* HTML: <div class="loader"></div> */
.loader3 {
  position: relative;
  top: 50%;
  left: 50%;
  width: 50px;
  --b: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  padding: 1px;
  background: conic-gradient(#0000 10%, #f03355) content-box;
  --webkit-mask: repeating-conic-gradient(#0000 0deg,
      #000 1deg 20deg,
      #0000 21deg 36deg),
    radial-gradient(farthest-side,
      #0000 calc(100% - var(--b) - 1px),
      #000 calc(100% - var(--b)));
  -webkit-mask-composite: destination-in;
  mask-composite: intersect;
  animation: l4 1s infinite steps(10);
}

@keyframes l4 {
  to {
    transform: rotate(1turn);
  }
}



.login_left_mainDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  height: 506px;
  background-color: white;
  box-shadow: 0px 0px 24px 25px #efefef;
  border-radius: 7px;
}

.login_back_button {
  width: 64px;
  height: 64px;

  gap: 0px;
  border-radius: 32px;
  opacity: 0.8px;
  color: #ff6e3f;
  align-self: flex-start;
  cursor: pointer;
}

._back_button {

  width: 45px;
  height: 45px;
  color: #ff6e3f;

  cursor: pointer;
}

.login_forgot_password {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  line-height: 17.58px;
  text-align: right;
}

.login_forgot_password_link {
  color: #ff6e3f;
}

.signup_google_div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.login_page_input {
  font-size: small;
  font-family: Roboto;
  font-size: small;
  font-weight: 500;
  line-height: 19.92px;
  color: #808080;
  width: 290px;
}

.loginPageErrorMessage {
  color: red;
  font-size: small;
  font-weight: 600;
  position: absolute;
  top: 93%;
  left: 1%;
  transform: translateY(5px);
  white-space: nowrap;
}

.loginPageMainDiv {
  margin: 25px 0;
}


.signup_text_in_ {
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  font-family: 'Roboto';
}


.right_dashboard_outlet {
  width: 100%;
  margin-top: 68px;
  padding: 20px;
  overflow-y: scroll;
  height: calc(100vh - 68px);
  box-sizing: border-box;
}

.errorMessage {
  color: red;
  font-size: 0.875rem;
  position: absolute;
  transform: translateY(5px);
  white-space: nowrap;
}


.asignleadbtn {
  padding: 0px 4px;
}

/* All Tables */
.showDetailEye {
  color: #5E6E93;
  font-size: 22px !important;

}

.Filter_fontSize {
  font-size: 15px;
}

:root {
  --navbar-height: 12.6vh;
}

.nav-link {
  padding-top: 0;
  padding-bottom: 0;
}

#navbars_main_div {
  position: fixed;
  width: 100vw;
  height: 12.5vh;
}

.main-content {
  padding-top: 12.5vh;
}



.otp-letter-input {
  max-width: 50px;
  height: 50px;
  border: 1px solid #cbccd7;
  border-radius: 10px;
  text-align: center;
}

.otp-letter-input::placeholder {
  color: red;
}

.otp_text_in_otp_left_mainDiv {
  font-family: Roboto;
  font-size: 35px;
  font-weight: 600;
  line-height: 47px;
  text-align: center;
  color: #1f1f1f;
}

#resend_otp_text {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.09px;
  text-align: center;
  padding-top: 20px;
}

.resend_otp_text_link {
  color: #ff7006;
}

.otp_verification_small_text {
  margin-bottom: 10px;
  text-align: center;
}

:root {
  --navbar-height: 12.6vh;
}

.pipeline_stage_mainDiv {
  padding-top: 12.6vh;
}

.row-div {
  height: calc(100vh - var(--navbar-height));
}

.pipeline_stage_mainDiv2 {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

#pipeline_stage_div_icons {
  cursor: pointer;
  display: flex;
}

#pipeline_stage_div_text {
  cursor: pointer;
}


.reset_password_form_control {
  width: 100% !important;
}


.role_and_permission {
  background-color: rgba(255, 255, 255, 1);
  border-top: 1px solid rgba(229, 235, 249, 1);
  border-radius: 10px;
  box-shadow: 2px 3px 30px 0px rgba(64, 123, 255, 0.12);
}

.role_and_permission_role_options {
  display: flex;
  flex-wrap: wrap;
}

.role_and_permission_role_module_text {
  font-family: Roboto;
  font-size: 19px;
  font-weight: 400;
  line-height: 23.44px;
}

.role_and_permission_role_module_checks {
  display: flex;
  align-items: start;
  flex: 1 1 159px;
  margin: 0 50px 0 0;
  gap: 12px;
}

.role_and_permission_role_module_checks>label {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
}

.role_and_permission_role_module_checks>input {
  cursor: pointer;
}


.role_and_permission {
  background-color: rgba(255, 255, 255, 1);
  border-top: 1px solid rgba(229, 235, 249, 1);
  border-radius: 10px;
  box-shadow: 2px 3px 30px 0px rgba(64, 123, 255, 0.12);
}


.role_and_permission_role_module_text {
  font-family: Roboto;
  font-size: 19px;
  font-weight: 400;
  line-height: 23.44px;
}


.role_and_permission_role_module_checks>input {
  cursor: pointer;
}

/* module collapse */
.role_and_permission_details_main {
  border: 1px solid #dfe5f1;
  padding: 7px;
  flex-wrap: wrap;
  border-radius: 10px;
  margin: 10px 20px 20px 20px;
  box-shadow: 0px 4px 22px 0px #5869ff1f;
}

.role_and_permission_faq_summary {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}


.signup_body_div {
  background-color: white;
  height: 80vh;
  padding: 18px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
}

.signup_mainbox {
  height: 100%;
  width: 50%;
  background-image: url(./images/bac2.png);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
}

.login_mainimg {
  width: 100%;
  height: 50%;
  box-sizing: border-box;
padding-left: 135px;
  display: flex;
}

.loginPageMainDiv svg {
  float: inline-end;   
  margin-top: -25px;
  margin-right: 11px;
}


.container-fluid.LeadRightSectionTable_body.table-responsive {
  background: #F3F5FF;
  height: 100vh;
}

.signup_left_container {
  height: 100%;
  width: 95%;
  max-width: 650px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.usertitle {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  color: #1F1F1F;          
  margin: 0 0 20px;
  padding: 20px;
  text-align: center;
}

.opt_verificationcreate {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
}


.signup_input_icons {
  position: absolute;
  right: 10px;
  top: 70%;
  transform: translateY(-50%);
  width: 17px;
  height: 17px;
  cursor: pointer;

}

.cpasswordErrorPara {
  margin-top: -17px;
  font-size: small;
  color: red;
}

.required_sign {
  color: red;
}

.errorMessageSignup {
  color: red;
  font-size: 0.875rem;
  position: absolute;
  top: 92%;
  transform: translateY(5px);
  white-space: nowrap;
}


.signup_right_bodyDiv {
  background-image: url(./images/bac2.png);
  background-repeat: no-repeat;
  background-size: cover;

}


.super_admin_div {
  background-color: rgba(255, 255, 255, 1);
  border-top: 1px solid rgba(229, 235, 249, 1);
  border-radius: 10px;
  box-shadow: 2px 3px 30px 0px rgba(64, 123, 255, 0.12);
}

.super_admin_first_div>.dashboard_username_div {
  display: flex;
  justify-content: space-between;
}


.super_admin_first_section_user_name2 {
  font-family: Roboto;
  font-size: 17px;
  font-weight: 400;
  text-align: left;
  color: rgba(114, 114, 114, 1);
}

.super_admin_first_section_user_name {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: rgba(31, 31, 31, 1);
  margin-bottom: -6px;
}

.super_admin_first_section_single_card {
  display: flex;
  align-items: center;
  /* height: 100px; */
  border-radius: 12px;
  padding: 15px;
  /* width: 24%; */
}

.super_admin_first_section_cards_div {
  display: flex;
  gap: 20px;
}


.super_admin_first_section_text_div {
  display: flex;
  flex-direction: column;
  margin: 26px 0 0 15px;

}


.super_admin_first_section_card_text {
  font-family: Roboto;
  font-size: larger;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  margin-bottom: 0px;
}

.super_admin_first_section_card_number {
  font-family: Roboto;
  font-size: 21px;
  font-weight: 700;
  line-height: 28px;

}


.super_admin_first_section_card_icon_div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  border: 1.5px;
  border-radius: 50%;
  background-color: white;
}

.super_admin_first_section_card_link {
  text-decoration: none;
  color: inherit;
  font-size: 16px;
}

.super_admin_first_section_first_card {
  background-color: #baebff;
  border: 1px solid #8ed4ef;


}

.super_admin_first_section_second_card {
  background-color: #c8ffdc;
  border: 1px solid #7bdc9e;
}

.super_admin_first_section_third_card {
  background-color: #ffddd3;
  border: 1px solid #fdbdaa;
}

.super_admin_first_section_fourth_card {
  background-color: #e5e8ff;
  border: 1px solid #abc3f8;
}

.super_admin_first_section_firstCard_icon {
  color: #8ed4ef;
}

.super_admin_first_section_secondCard_icon {
  color: #7bdc9e;
}

.super_admin_first_section_thirdCard_icon {
  color: #f4a48b;
}

.super_admin_first_section_fourthCard_icon {
  color: #8298c9;
}



/* Section 2 */
.super_admin_section2 {
  margin-top: 20px;
}

.super_admin_section2_user_name {
  font-family: Roboto;
  font-size: 15px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: rgba(31, 31, 31, 1);
  margin-bottom: -6px;
}

.super_admin_section2_user_name2 {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  color: rgba(114, 114, 114, 1);
}

.super_admin_section2_right_div {
  border: 1px solid #e6e6e6;
  border-radius: 12px;
}

#super_admin_section2_right_div_text {
  font-family: Roboto;
  font-weight: 600;
  line-height: 23.44px;
  text-align: left;
}

.super_admin_section2_right_div_label_text {
  font-family: Roboto !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.super_admin_section2_table_icon {
  color: #888a88;
}

.super_admin_section2_table_tbody_tr {
  cursor: pointer;
}

.super_admin_section2_right_checkbox_div {
  display: flex;
  justify-content: space-between;
}



/* User Modal */
.super_admin_create_user_modal {
  padding: 0 !important;
}


/* Created User */
.created_user_table_action_delete {
  cursor: pointer;
}


.img-account-profile {
  height: 10rem;
}

.rounded-circle {
  border-radius: 50% !important;
}

.card {
  box-shadow: 0 0.15rem 1.75rem 0 rgb(33 40 50 / 15%);
}

.card .card-header {
  font-weight: 500;
}

.card-header:first-child {
  border-radius: 0.35rem 0.35rem 0 0;
}

.card-header {
  margin-bottom: 0;
  background-color: rgba(33, 40, 50, 0.03);
  border-bottom: 1px solid rgba(83, 77, 107, 0.125);
}

.dataTable-input {
  display: block;
  width: 100%;
  padding: 0.875rem 1.125rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1;
  color: #69707a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #c5ccd6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.35rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.nav-borders .nav-link.active {
  color: #0061f2;
  border-bottom-color: #0061f2;
}

.nav-borders .nav-link {
  color: #69707a;
  border-bottom-width: 0.125rem;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0;
  padding-right: 0;
  margin-left: 1rem;
  margin-right: 1rem;
}

.account_view_details_main_container {
  background-color: rgba(255, 255, 255, 1);
}

.account_view_details_Row {
  padding: 20px;
  margin-top: 20px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(229, 235, 249, 1);
  box-shadow: 2px 3px 30px 0px rgba(64, 123, 255, 0.12);
  border-radius: 20px;

}


.create_user_img {
  width: 130px;
  height: 130px;
  border: 3px;
  border: 3px solid rgba(248, 201, 167, 1);
  border-radius: 50%;
  margin-right: 20px;
}

.create_change_upload_file_btn {
  width: 128px;
  height: 50px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 1);
  border: 1px solid rgba(194, 200, 212, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.create_change_upload_file_btn>label {
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  line-height: 19.92px;
  text-align: center;
  color: rgba(107, 112, 125, 1);
  cursor: pointer;
}

.create_lead_remove_img_btn {
  width: 150px;
  height: 50px;
  border-radius: 8px;
  border: 1px solid rgba(221, 221, 221, 1);
  background-color: rgba(88, 105, 255, 1);
  box-shadow: 2px 3px 18px 0px rgba(147, 130, 254, 0.12);
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  line-height: 19.92px;
  text-align: center;
  color: rgba(253, 254, 254, 1);
  margin: 10px;
}

.create_lead_user_img_div {
  display: flex;
  align-items: center;
  padding: 30px;
  flex-wrap: wrap;
}

.create_leads_use_img_btns {
  display: flex;
  flex-wrap: wrap;
}

/* From */
.create_lead_form_main_div {
  padding: 40px 20px 20px 20px;
}



.createuser-btn .default-btn {
  background: linear-gradient(180deg, #5869FF 0%, #394EFF 52%, #394EFF 100%);
}


.create_call_form {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid rgba(128, 127, 127, 0.24);
  border-radius: 5px;
}

.datalist_dropdown {
  position: absolute;
  left: 0px;
  top: 95%;
  width: 100%;
  background-color: rgb(250, 238, 238);
  border-radius: 0px 0px 5px 5px;
  z-index: 111;
  padding: 15px;
}

.createLeadInput label {
  padding-left: 2px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: black;
}





/* form section 2 */
.LeadRightSectionTable_body .table-responsive {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 45px;
  text-align: left;

}


.create_lead_form_submitBtn {
  width: 147px;
  height: 52px;
  border-radius: 12px;
  border: 1px;
  background-color: rgba(88, 105, 255, 1);
  border: 1px solid rgba(221, 221, 221, 1);
  box-shadow: 2px 3px 18px 0px rgba(147, 130, 254, 0.12);
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: rgba(255, 255, 255, 1);
  margin-top: 20px;
}

.create_lead_form_submitBtn:hover {
  background-color: rgb(38, 59, 252);
}

.change_passward {
  border: 1px solid grey;
  color: rgb(41, 40, 40);
  padding: 4px 10px;
  border-radius: 5px;
  cursor: pointer;
}


.dashboard_leadView_details_heading {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: rgba(54, 54, 54, 1);
  margin-bottom: -10px;
}

.dashboard_leadView_details_heading_second {
  font-family: monospace;
  font-size: 16px;
  font-weight: 800;
}

.lead_costumer_details_firstRow_left_imgDiv {
  width: 150px;
  height: 150px;
  border: 3px solid rgba(248, 201, 167, 1);
  border-radius: 50%;
  margin-bottom: 20px;
}

.lead_costumer_details_firstRow_left_img {
  height: 100%;
  border-radius: 50%;
}

.lead_view_details_table_td {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 18.75px;
  /* text-align: left; */
  color: rgb(116, 113, 113);
}



.lead_view_details_costumer_table_th {
  /* padding-right: 80px; */
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 19.92px;
  text-align: left;
  color: rgba(128, 128, 128, 1);
}

#signup_left_mainDiv {
  height: 104%;
  border-radius: 6px;
  border: 8px;
  border: 8px;
  padding: 0px 20px;
  background-color: #ffffff;
  background-color: #ffffff;
  /* background-color: #ffffff; */
  background-color: #F3F5FF;
  /* margin-top: 32px; */
  width: 75%;
  display: flex;
  justify-content: center;
}


.signup_text_in_signup_left_mainDiv {
  padding-top: 20px;
  font-family: Roboto;
  font-size: 28px;
  font-weight: 600;
  line-height: 47px;
  text-align: center;
}

.signup_fullname_div {
  display: flex;
  justify-content: space-evenly;
  gap: 5px;
}

.signup_div_name {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: center;
}

.signup_name_form_control {
  border: 1px solid #cbccd7;
}


.signup_name_form_control::placeholder {
  font-family: Roboto;
  font-size: small;
  font-weight: 500;
  line-height: 19.92px;
  color: #808080;
}


.signup_name_icons {
  position: absolute;
  right: 8px;
  top: 70%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  opacity: 1;
  background-color: #ffffff;
}



.signup_submit_div {
  text-align: center;
}

button.signup_submit_button{
 align-items: center;  
 background: #5869ff;
 border: 1px solid #ddd;
 border-radius: 12px;
 box-shadow: 2px 3px 18px 0 #9382fe1f;
 color: #fff;
 font-family: Roboto;
 font-size: 18px;
 font-weight: 600;
 height: 42px;
 text-align: center;
 width: 130px;
}

.signup_input_div {
  padding: 0 50px;
}

.signup_div_input {
  color: #1f1e1e;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-align: center;
}

.cpasswordErrorPara {
  margin-top: -17px;
  font-size: small;
  color: red;
}

.required_sign {
  color: red;
}

.errorMessageSignup {
  color: red;
  font-size: 13px;
  position: absolute;
  top: 92%;
  transform: translateY(5px);
  white-space: nowrap;
}

.dashboard_main_container {
  background-color: rgba(255, 255, 255, 1);
  padding: 15px 15px;
  border-radius: 12px;
}

.dashboard_user_name {
  font-family: Roboto;
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  margin-bottom: -6px;
}

.dashboard_user_name2 {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 450;
  text-align: left;
}

.dashboard_filter_sidebar_row {
  height: 100%;
}

.LeadRightSectionTable_body {
  box-sizing: border-box;
  overflow: auto;
}


.LeadRightSectionTable_body thead {
  position: sticky;
  top: 0;
  z-index: 1;

}

table.table {
  margin: 0;
}

.delatebox {
  width: 100%;
  padding: 20px;
}

.delatebox .delatebox_form {
  display: flex;
  flex-Direction: column;
  align-Items: center;
  justify-Content: center;
}


.delatebox_form .delatebox_main {
  width: 80%;
  display: flex;
  justify-Content: space-around;
}

.delatebox_main input {
  width: 50px;
  height: 40px;
  padding: 8px;
  text-Align: center;
  border: 1px solid black;
  outline: none;
}

.MPIN_btn {
  width: "100%";
  display: flex;
  align-Items: center;
  justify-Content: space-between;
  margin-Top: 24px;
}

.MPIN_btn span {
  margin-right: 156px;
}

.MPIN_btn .MPIN_button {

  border-Radius: 5px;
  padding: 4px 7px;
}




@media (max-width:600px) {

  usertitle {
    padding: 14px 0px 1px;
  }

  button.signup_submit_button{
    font-size: 16px;
    width: 130px;
    height: 43px;
  }
  .signup_left_container{
    width: 100%;
  }

  .container-fluid.LeadRightSectionTable_body.table-responsive{
    display: flex;
    align-items: center;
    justify-content: center;
  }



  .forgot_email_form_control {
    width: 220px;
  }

  .search_main {
    display: none;
}

  .forgot_input_icons {
    right: 4%;
    top: 74%;
  }


  .create_lead_form_submitBtn {
    width: 113px;
    height: 43px;
    font-size: 16px;
   
}

.dashboard_section2_chart_div{
  width: 250px;
  height: 250px;
}
 

  .super_admin_first_section_right_div>button {
    width: 90px !important;
    height: 35px !important;
  }

  .super_admin_first_section_card_text {
    line-height: 22px;
    font-size: small;
  }

  .super_admin_first_section_card_number {
    font-size: large; 
  }

  .super_admin_div {
    margin-top: 15px;
  }

  .super_admin_first_section_user_name2 {
    font-size: 12px;
    line-height: 0px;
    margin-top: 10px;
  }

  .super_admin_first_section_user_name {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: 12px;
    text-align: left;
    color: rgba(31, 31, 31, 1);
    margin-bottom: -4px;
  }
 
  .signup_div_name{
    font-size: 14px;
  }


  .signup_div_input{
    font-size: 14px;
  }
  .dashboard_leads_verify_btn_div>button {
    width: 110px;
    height: 43px;
  }

  .dashboard_leads_action_btn_div>button {
    width: 110px;
    height: 43px;
  }

  .create_change_upload_file_btn {
    width: 80px;
    height: 40px;
    margin: 5px;
  }

  .create_lead_remove_img_btn {
    width: 80px;
    height: 50px;
    margin: 5px;
  }

  .form-control {
   
    font-size: 12px;
  }

  .create-lead-heading {
    margin: 0px 0px 20px 0px;
  }

  .create-lead-heading-right-part {
    margin: 0px 0px 0px 20px;
  }


  .dashboard_leads_verify_btn_div>button {
    width: 110px; 
    height: 43px;
  }

  .dashboard_leads_action_btn_div>button {
    width: 110px;
    height: 43px;            
  }

  .sidebar_logout_btn {
    margin: 17vh 0 0 -5%;
  }


  .dashboard_leads_table_div>div {
    flex-direction: column;
  }



  .dashboard_leads_main_container {
    height: auto;
    min-width: 500px;
  }
  .dashboard_leads_pagination_pageItem {
    margin: 10px 2px;
    height: 29px;
    width: 40px;
    font-size: 15px !important;
  }

  li.page-item.dashboard_leads_pagination_pageItem span.page-link {

    display: inline-block;
    margin-top: -2px;
    font-size: 12px;
  }

  .navbar-right-menu-mannual {
    padding: 0px;
  }

  .create_change_upload_file_btn {
    width: 80px;
    height: 40px;
    margin: 5px;
  }

  .create_lead_remove_img_btn {
    width: 80px;
    height: 50px;
    margin: 5px;
  }

  .create-lead-heading {
    margin: 0px 0px 20px 0px;
  }

  .create-lead-heading-right-part {
    margin: 0px 0px 0px 20px;
  }

  .lead_costumer_details_firstRow_left_imgDiv {
    width: 110px;
    height: 110px;
  }



  .create_change_upload_file_btn {
    width: 80px;
    height: 40px;
    margin: 5px;
  }

  .create_lead_remove_img_btn {
    width: 80px;
    height: 50px;
    margin: 5px;
  }

  .create-lead-heading {
    margin: 0px 0px 20px 0px;
  }

  .create-lead-heading-right-part {
    margin: 0px 0px 0px 20px;
  }

  .landingPageTitleDivPara {
    font-size: 35px;
    line-height: normal;
  }

  .landingPageSlogan>p {
    font-size: 15px;
    line-height: normal;
  }

  .landingPageTryBtn {
    width: 160px;
    height: 50px;
    font-size: 16px;
  }

  .landingPageTitleDivIcon3 {
    width: 60px;
    left: 73%;
    top: 17%;
  }

  .landingPageTitleDivIcon1 {
    top: 0;
    width: 12%;
  }

  /* .landingPageTitleDivIcon2 {
    top: 15%;
    width: 12%;
    left: 44%;
  } */

  .landingPageFirstPartLoginBtn {
    width: 100px;
    height: 40px;
  }

  .landingPageFirstPartLoginBtnDiv2 {
    top: 15px;
  }

  .landingPageFirstPartLoginBtnDivLogo {
    width: 25%;
    top: 16px;
  }



  .landingPageTryBtn {
    margin-top: 90px;
  }

  .landingPageFooterList {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .landingPageFooterList h3 {
    text-align: center;
  }

  .landingpageFourthPartMainDivHeading {
    font-size: 30px;
  }

  .landingPageThirdPartHeadings {
    text-align: center;
  }


 

  .signup_right_bodyDiv {
    height: 54vh;
    margin: 30px 0 0 auto;
  }

  .otp_verification_row {
    width: 100%;
    text-align: end;
  }

  .signup_body_div{
    padding: 18px 0px;
  }

  .CREATEPOP_FORM {
    width: 85% !important;
}

.createupdate_innerform{
  padding: 0px !important;
}

.role_and_permission_details_main{
  min-width: 165px !important;
  margin: 6px !important;
  padding: 4px 12px !important;
  width: 85% !important;
}

.role_and_permission_faq_summary {
font-size: 16px !important;
}

.dashboard_row1{
  flex-direction: column;
  gap: 12px;
}

.responsivetable_dashboard{
  width: 100%;
}

.piechart_responsive {
  width: 100%;
}
.MPIN_btn{
 display: flex;
 gap: 100px;
}
.MPIN_btn span{
  font-size: 12px;
  margin-right: 10px;
}
.modal-title {
  font-size: 18px !important;
}
.toast-container{
  max-width: 310px;
  right: 0px !important;
}
.actmodalupdate{
  width: 100%;
}
.create_lead_form_main_div{
  padding: 0px;
}
.actmodalupdate{
  width: 95% !important;
}
.modal-content{
  width: 95% !important;
  min-width: 280px !important;
}
.modal.show .modal-dialog{
  max-width: 450px !important;
}
.createupdate_meeting{
  width: 100% !important;
}

 }

  






@media (min-width: 601px) and (max-width: 767px) {
  .landingPageTitleDivPara{
    font-size: 42px !important;
  }
  .createupdate_meeting{
    width: 100% !important;
  }
  .dropdown-menu{
    width: 100% !important;
  }
  .modal-content{
    width: 100% !important;
  }
  .actmodalupdate{width: 90% !important;
  }
  .toast-container{
    max-width: 410px;
    right: 10px !important;
  }
  .productservices_modal_content 
  {
    width: 65% !important;
    min-width: 300px !important;
  }

 .product_add{
  font-size: 14px !important;
  padding: 5px 12px !important;
 }

  .login_mainimg{
    height: 37%;
    padding-left: 45px;
  }
  .dashboard_row1{
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .responsivetable_dashboard {
    width: 100%;
  }

  .piechart_responsive {
    width: 100%;
    margin-top: 20px;
  }


  .super_admin_first_section_cards_div {
    flex-wrap: wrap;
  }

  .super_admin_first_section_single_card {
    min-width: 258px;
  }


  .search_main{
    width: 240px;
    padding: 0px;
  }


  .dashboard_leads_table_div>div {
    flex-direction: column;
  }



  


  .dashboard_section1_mainDiv {
    max-height: 28vh;
    overflow-y: auto;
  }

  .dashboard_section1_mainDiv thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }


  .dashboard_section2_row2 {
    margin-top: 20px;
  }

  .dashboard_section1_mainDiv {
    max-height: 28vh;
    overflow-y: auto;
  }

  .dashboard_section1_mainDiv thead {
    position: sticky;
    top: 0;
    z-index: 1;
  }

  /* .dashboard_leadView_details_heading {
    text-align: center;
  } */

  .dashboard_leadView_details_heading_second {
    /* text-align: center; */
    font-family: monospace;
    font-size: 14px;
    font-weight: 800;
  }

  /* .dashboard_leadView_company_details_heading {
    text-align: center;
  } */

  .create_user_img {
    margin-bottom: 10px;
  }

  .create_lead_user_img_div {
    flex-wrap: nowrap;
  }

  .create_user_img {
    border: none;
  }

  .create_leads_use_img_btns {
    justify-content: center;
    margin-top: -30px;
  }



  .testimonial_sec {
    background-position: bottom;
  }

  .landingPageTitleDivIcon1 {
    left: -40%;
    top: 5%;
  }

  .landingPageTitleDivIcon3 {
    left: 76%;
    top: 30%;
  }

  .landingPageFooterList {
    margin: 20px 0;
  }

  .sidebar_navbar_mainDiv {
    margin-top: -8vh;
  }

  .sidebar_logout_btn {
    margin: 4.3vh 0 0vh -5%;
  }

  .dashboard_sidebar_main_div.shrink .sidebar_shrink_logout_btn {
    margin: 24vh 0 2vh -5%;
  }



  span.dashboard_navbar_userName {
    font-size: 13px;
    line-height: 7px;
  }

  .navbar-right-menu-mannual {
    padding: 4px 5px 0px 0px;
  }

  .dashboard_navbar_panel {
    font-size: 13px;
  }

  button.default-btn {
    padding: 5px;
    font-size: 12px;
  }

  .filter_div {
    display: flex;
    flex-direction: column;
  }

  #signup_left_mainDiv {
    margin: 20px auto;
  }

  .dashboard_navbar {
    background-color: rgba(255, 255, 255, 1);
  }

  .dashboard_navbar_dashboard_text {
    margin-left: 0px;
  }

  .sidebar_logout_btn {
    margin: 12.8vh 0 0 -5%;
  }

  .sidebar_hide_smallScreen {
    display: none;
  }

  .sidebar_display_smallScreen {
    display: block;
  }


  .dashboard_sidebar_main_div .shrink {
    display: none;
  }

  .sidebar_hamburger_icon {
    display: none;
  }

  .dashboard_sidebar_main_div {
    position: fixed;
    top: 0;
    left: 0;
    /* width: 250px; */
    height: 100%;
    overflow-x: hidden;

    transition: 0.5s;
    z-index: 1000;

  }

  .sidebar_container_fluid {
    position: relative;

  }

  .sidebar_hide_smallScreen {
    transform: translateX(-100%);
  }

  .sidebar_display_smallScreen {
    transform: translateX(0);
  }

  .lead_view_details_costumer_details {
    height: 200px;
  }

  .dashboard_leads_table_div>div {
    flex-direction: column;
  }


  .dashboard_leads_main_container {
    height: auto;
  }

  

 


  .signup_name_form_control {
    width: 100% !important;
  }

  .signup_name_icons {
    right: 4%;
    top: 74%;
  }

  .signup_email_form_control {
    width: 100%;
  }

  .signup_input_icons {
    right: 4%;
    top: 74%;
  }

 

  .super_admin_first_section_card_text {
    font-size: large;
  }

  .costum-page-testimonial-title {
    font-size: 2.5rem !important;
    font-weight: 400;
  }

  .landingPageSecondPartPara {
    padding: 10px 0;
  }

  .landingpageFourthPartCarouselDiv {
    padding-top: 40px !important;
    padding-bottom: 20px !important;
  }

  .landingPageTitleDiv {
    left: 0;
    width: auto;
  }

  .landingPageTitleDivIcon3 {
    left: 65%;
    top: 17%;
  }


  .landingPageFirstPartLoginBtnDiv {
    width: auto;
    margin: 0 auto;
  }

  .landingPageThirdPartHeadings {
    text-align: center;
  }

  .loader {
    position: fixed;
    left: 30%;
    top: 50%;
    --w: 10ch;
    font-weight: bold;
    font-family: monospace;
    font-size: 30px;
  }

 


  #second_navbar {
    display: flex !important;
    flex-direction: column !important;
  }

  #second_navbar_left_side {
    float: left !important;
  }

  .dashboard_leads_table_div>div {
    flex-direction: column;
  }




  .signup_name_form_control {
    width: 100% !important;
  }

  .signup_name_icons {
    right: 4%;
    top: 74%;
  }

  .signup_email_form_control {
    width: 100%;
  }

  .signup_email_form_control::placeholder {
    font-size: small;
  }

  .signup_input_icons {
    right: 4%;
    top: 74%;
  }



  .role_and_permission_details_main{
    min-width: 160px !important;
    margin: 5px 11px !important;
    padding: 4px 16px !important;
  }
  .CREATEPOP_FORM {
    width: 80% !important;
}

.createLeadInput {
  
  min-width: 218px !important;
}

}

@media (min-width: 769px) and (max-width: 1024px) {
  .landingPageTitleDivPara{
    font-size: 55px !important;
  }

  .createupdate_meeting{
   min-width: 289px !important;
  }
  .leadupdate_popformnew{
    width: 80% !important;
  }

  .createLeadInput {
  
    min-width: 220px !important;
  }
  .modal-content{
    min-width: 500px !important;
  }
  .CREATEPOP_FORM {
    width: 75% !important;
}
  .role_and_permission_details_main{
    min-width: 190px !important;
    width: 40% !important;
  }
  .productservices_modal_content 
  {
    width: 65% !important;
    max-width: 500px !important;
  }


  .login_mainimg {
    height: 37%;
    padding-left: 45px;
}
  .responsivetable_dashboard {
    width: 49%;
  }

  .piechart_responsive {
    width: 49%;

  }

  .super_admin_first_section_card_text {
    line-height: 22px;
    font-size: medium;
  }

  .super_admin_div {
    margin-top: 15px;
  }

  .sidebar_navbar_mainDiv {
    margin-top: -8vh;
  }

  .sidebar_logout_btn {
    margin: 4.3vh 0 0vh -5%;
  }

  .dashboard_sidebar_main_div.shrink .sidebar_shrink_logout_btn {
    margin: 24vh 0 2vh -5%;
  }

  .navbar-right-menu-mannual {
    align-items: center;
    padding: 0;
  }

  .dashbaord_navbar_crm_text {
    padding: 25px 0px 10px 10px;
    text-align: center;
    width: 100%;
  }

  .sidebar_close_btn {
    display: none;
  }

  .forgot_email_form_control {
    width: 400px;
  }

  .reset_password_form_control {
    width: 400px !important;
  }


  .super_admin_first_section_cards_div {
    flex-wrap: wrap;
  }

  .super_admin_first_section_single_card {
    min-width: 310px !important;
  }
}



@media (min-width: 1025px) and (max-width: 1400px) {

  .leadupdate_popformnew {
    width: 80% !important;
}
  .CREATEPOP_FORM {
    width: 75% !important;
}
  .role_and_permission_details_main{
    min-width: 287px;
    width: 40% !important;
  }
  .usertitle {
    padding: 15px 0px 2px 0px;
  }

  .productservices_modal_content 
  {
    width: 50% !important;
    min-width: 500px !important;
  }


  .sidebar_close_btn {
    display: none;
  }

  .super_admin_first_section_single_card {
    min-width: 210px;

  }

  .dashboard_mainDiv_card {
    width: 170px;
  }

  .dashboard_card_mainDiv {
    justify-content: center;
  }

  .dashboard_sidebar_main_div.shrink .dashboard_card_mainDiv {
    justify-content: center;
  }

  .create_task_reminder {
    margin-top: 20px;
  }

  .account_view_details_table {
    padding-right: 40px;
  }

  .create_meeting_participant_btn {
    margin-top: 26px;
    text-decoration: none;
  }


  .costum-page-testimonial-title {
    font-size: 2.5rem !important;
    font-weight: 400;
  }

  .landingPageSecondPartPara {
    padding: 10px 0;
  }

  .landingpageFourthPartCarouselDiv {
    padding-top: 40px !important;
    padding-bottom: 20px !important;
  }

  .landingPageTitleDiv {
    left: 0;
    width: auto;
  }

  
  .landingPageFirstPartLoginBtnDiv {
    width: auto;

  }


  .account_view_details_table {
    width: 100%;
  }

  
  .sidebar_logout_btn {
    margin: -5vh 0 0vh -5%;
  }

  .dashboard_sidebar_main_div.shrink .sidebar_shrink_logout_btn {
    margin: 3.2vh 0 2vh -5%;
  }
  .company_page_size{
    width: 50px !important;
    height: 35px !important;
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1401px) {


  .leadupdate_popformnew {
    width: 80% !important;
}
  .sidebar_close_btn {
    display: none;
  }

  .dashboard_section1_mainDiv {
    max-height: 24vh;
    overflow-y: auto;
  }

  .dashboard_section1_mainDiv table {
    width: 100%;
    table-layout: fixed;
  }

  .dashboard_section1_mainDiv thead {
    position: sticky;
    top: 0;
    z-index: 1;

  }

  .dashboard_section1_mainDiv::-webkit-scrollbar {
    width: 0;

    background: transparent;
  }

  .dashboard_section2_table_mainDiv {
    max-height: 32vh;
    overflow-y: auto;
  }

  .dashboard_section2_table_mainDiv table {
    width: 100%;
    table-layout: fixed;
  }

  .dashboard_section2_table_mainDiv thead {
    position: sticky;
    top: 0;
    z-index: 1;

  }

  .dashboard_section2_table_mainDiv::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .dashboard_section3_table_mainDiv {
    max-height: 25vh;
    overflow-y: auto;
  }

  .dashboard_section3_table_mainDiv table {
    width: 100%;
    table-layout: fixed;
  }

  .dashboard_section3_table_mainDiv thead {
    position: sticky;
    top: 0;
    z-index: 1;

  }

  .dashboard_section3_table_mainDiv::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }

  .super_admin_section2_table {
    height: 500px !important;
    overflow-y: auto;
  }

  .super_admin_section2_table_thead {
    position: sticky !important;
    top: 0 !important;
    z-index: 1 !important;
  }

  .super_admin_section2_table::-webkit-scrollbar {
    width: 4px;
  }

  .super_admin_section2_table::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #ff7006;
  }

  .table-container::-webkit-scrollbar-thumb:hover {
    height: 10px;
    background: #ff7006;
  }
  .company_page_size{
    width: 43px;
    height: 38px;
  }
}


.signup_right_bodyDiv {
  height: 54vh;
  margin: 30px 0 0 auto;
}

.otp_verification_row {   
  width: 100%;
  text-align: end;
}


.super_admin_first_section_card_text {
  font-size: small;
}



.landingPageTryBtn {
  margin-top: 70px;
}





.landingpageFourthPartMainDiv {
  background-size: cover;
}

.landingPageThirdPartHeadings {
  text-align: center;
}

.super_admin_first_section_cards_div{
  flex-wrap: wrap;
}

.super_admin_first_section_single_card{
  min-width: 258px;
  height: 80px;
}






.CREATEPOP_FORM{
width: 60%;

}

.createupdate_innerform{
padding: 10px 32px !important;
}

.filter-btn{
padding: 5px 12px;
font-size: 12px;
}

.page_size_control{
font-size: 14px;
margin-right: 7px;
padding-bottom: 3px !important;
}



span.dashboard_navbar_userName {
font-size: 12px;
line-height: 7px;
margin-top: 15px;
}

.dashboard_navbar_panel{
font-size: 12px;
}

.dashboard_navbar_drop_icon{
margin-top: -12px;
margin-left: -4px;
height: 25px;
}



.dashboard_rightLeads_main_container{
min-width: 430px;
}

.productservices_modal_content{
padding: 16px 14px !important;
}

.addproductbtn{
font-size: 12px !important;
}

.productservices_input_div{
height: 40px !important;
}




.report_lead_genrated_first_table_btn {
  background-color: rgba(255, 237, 223, 1);
  border: 1px solid rgba(250, 171, 112, 1);
  color: rgba(255, 112, 6, 1);
  padding: 4px 6px;
}

.report_lead_genrated_first_table_btn:hover {
  border: 1px solid rgba(250, 171, 112, 1);
  color: rgba(255, 112, 6, 1);
}

.report_lead_genrated_first_table_btn:active,
.report_lead_genrated_first_table_btn:focus {
  background-color: rgba(255, 237, 223, 1);
  border: 1px solid rgba(250, 171, 112, 1);
  color: rgba(255, 112, 6, 1);
}


.report_lead_genrated_first_table_dropdown_item {
  color: rgba(255, 112, 6, 1);
}

.report_lead_genrated_first_table_dropdown {
  border: 1px solid rgba(170, 192, 241, 1);
}

.report_call_done_first_table_lineChart {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}


.landingPageFooterList ul {
  cursor: pointer;
}

.landingPageSocialLink {
  cursor: pointer;
}

.landingAboutPara {
  cursor: pointer;
}


.productservices_modal {
  position: absolute;
  inset: 0;
  background-color: rgba(97, 95, 95, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}


.productservices_modal_content {
  width: 40%;
  background-color: #fff;
  padding: 30px 50px;
  border-radius: 10px;
  display: flex;
  gap: 20px;
  flex-direction: column;
  min-width: 300px;

}


.productservices_input_div {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e1e3e1;
  border-radius: 5px;
  overflow: hidden;
}

.productservices_input_div select {
  width: 30%;
  border: none;
  outline: none;
  padding: 0px 10px;
  font-size: 17px;
}


.signup_firstbox{
width: 100%;
height: 100vh;
display: flex;
align-items: center;
justify-content: space-between;
}

.productservices_input_div_input {

  width: 70%;
  padding: 0px 10px;
  border-left: 1px solid #e1e3e1;
}


.productservices_input_div_input input {

  width: 100%;
  height: 100%;
  border: none;
  outline: none;

}

.addproductbtn {
  padding: 5px 10px;
  font-size: 20px;
}

.productservices_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 27px;
}









html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.btn-shiny2 {
  background-color: #007bff; /* Blue color for delete */
  border: none;
  color: white;
  padding: 4px 8px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.btn-shiny2:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.btn-cancel { 
  
  background-color: #6c757d; /* Grey color for cancel */
  border: none;
  color: white;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.btn-cancel:hover {
  background-color: #5a6268; /* Darker grey on hover */
}
.modal_bar{
  display: block;
  position: fixed;          /* Fixed position, so it stays in place when scrolling */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;            /* High z-index to ensure the modal appears above other content */
           /* Hidden by default */
  overflow: hidden;         /* Prevents scrolling outside the modal */
  outline: 0;
}

.NEWMODALupdate{
transition: height 2s linear 1s ;
  position: fixed;
  inset: 0;
  z-index: 1111;
  display: flex;
  align-items: start;
  justify-content: center;
   background-color: #5a626823;
   overflow-y: auto;
   padding-top: 80px;
}
.actmodalupdate{
width: 50%;
  background-color: #FFFFFF;
  border: 1px solid rgba(229, 235, 249, 1);
  border-radius: 12px;
}
.closemodalupdate{
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #d5daf3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
}
.modalContent{
  padding: 12px;
}
.role_and_permission_modules{
 display: flex;
 align-items: start;
 justify-content: space-between;
 flex-wrap: wrap;
}
.set_pin{
  position: fixed;
  z-index: 2222;
  inset: 0;
  background-color: rgba(0, 89, 255, 0.384);
}
.MPIN_digit::placeholder{
  color: red;
}
.AAAA{
  background-color: #0056b3;
}
